<template>
  <div>
    this is a simple page of content
  </div>
</template>
<script>
export default {
  data() {
    return {

    }
  }
}
</script>
<style lang="less" scoped>

</style>